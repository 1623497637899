<template>
  <div class="app-contianer" style="padding-bottom: 0">
    <PageBg />

    <div id="app-content">
      <div class="main-poster">
        <van-swipe class="my-swipe" :autoplay="4000" indicator-color="white" :show-indicators="true" @change="chengkey">
          <van-swipe-item v-for="item in posterList" :key="item.id">
            <div class="img-box">
              <canvas :id="item.id" v-show="false"></canvas>
              <img :show-menu-by-longpress="true" :src="item.base64" alt="" />
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="share-box" v-if="isApp">
        <div class="item" @click="saveImgApp">
          <div class="image">
            <img src="../../../assets/poster/saveLocal.png" alt="" />
            <div class="f34">保存到相册</div>
          </div>
        </div>
        <!-- <div v-if="agentId === 'A10017309148'" class="item" @click="saveWechat('WXSceneSession')">
          <div class="image">
            <img src="../../../assets/poster/shareWechat.png" alt="">
            <div class="f34">分享到微信</div>
          </div>
        </div>
        <div v-if="agentId === 'A10017309148'" class="item" @click="saveWechat('WXSceneTimeline')">
          <div class="image">
            <img src="../../../assets/poster/shareWechat2.png" alt="">
            <div class="f34">分享到朋友圈</div>
          </div>
        </div> -->
      </div>

      <div class="swiper-button" v-else>
        <span>长按图片保存海报至相册~</span>
      </div>
      <div class="poster-text">
        <div class="poster-rule">邀请规则:</div>
        <div class="poster-rule">{{ inviteRule }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getlist } from '@/api'
import { mapState } from 'vuex'
import PageBg from '@/components/PageBg.vue'
import thechangePhone from '@/components/changePhone.js'

const posterList = [
  {
    id: 'canvas2',
    img: require('../../../assets/poster/poster7.jpg'),
    code_X: 395,
    code_Y: 1040,
    codeWidth: '220',
    codeHeight: '220',
    base64: ''
  },
  {
    id: 'canvas3',
    img: require('../../../assets/poster/poster5.jpg'),
    code_X: 395,
    code_Y: 1040,
    codeWidth: '220',
    codeHeight: '220',
    base64: ''
  },
  {
    id: 'canvas4',
    img: require('../../../assets/poster/poster6.jpg'),
    code_X: 395,
    code_Y: 1040,
    codeWidth: '220',
    codeHeight: '220',
    base64: ''
  },
  {
    id: 'canvas5',
    img: require('../../../assets/poster/poster8.jpg'),
    code_X: 395,
    code_Y: 1040,
    codeWidth: '220',
    codeHeight: '220',
    base64: ''
  },
  {
    id: 'canvas1',
    img: require('../../../assets/poster/poster4.jpg'),
    code_X: 395,
    code_Y: 1040,
    codeWidth: '220',
    codeHeight: '220',
    base64: ''
  }
]

export default {
  name: 'tikcy',
  components: { PageBg },
  data() {
    return {
      posterType: 'default',
      posterList: posterList,
      qrCodeImg: '',
      key: 0,
      loading: false,
      inviteRule: '分享邀请码海报或邀请链接给好友，快和好友一起享受霸王餐的乐趣吧~'
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'agentConfig', 'isApp', 'agentId']),
    userName() {
      return this.custMsg.nickName ? this.custMsg.nickName : `用户${this.custMsg.custId}`
    }
  },
  created() {
    // 未绑定手机号
    if (this.custMsg.phoneUser === 'N') {
      thechangePhone()
    } else {
      this.getticky()
    }

    if (this.agentConfig.POSTER) {
      const POSTER = JSON.parse(this.agentConfig.POSTER)
      if (POSTER.length > 0) {
        this.posterList = POSTER
        this.posterList.forEach((item, index) => {
          item.id = 'canvas' + index
          item.code_X = item.code_X * 2
          item.code_Y = item.code_Y * 2
          item.codeHeight = item.codeHeight * 2
          item.codeWidth = item.codeWidth * 2
        })
        this.posterType = 'custom'
      }
    }

    // 自定义邀请规则
    if (this.agentConfig.USER_INVITE_RULE) {
      this.inviteRule = this.agentConfig.USER_INVITE_RULE
    }
  },

  methods: {
    // 改变轮播
    chengkey(val) {
      this.key = val
    },
    //获取二维码
    getticky() {
      let params = {
        trxCode: 'MS002',
        type: '00',
        url: `${this.agentConfig.SELF_DOMAIN_USER}/shop`
      }
      getlist(params).then((res) => {
        if (res.imageBase64) {
          this.qrCodeImg = res.imageBase64
          this.posterList.forEach((item) => {
            this.cimg(item)
          })
        } else {
          this.$toast.fail(res.rspMsg)
        }
        setTimeout(() => {
          this.loading = true
        }, 2000)
      })
    },

    // 绘制海报
    cimg(item) {
      console.log(item)
      const _this = this
      let canvas = document.getElementById(item.id)
      let width = 1005
      let height = 1371
      canvas.width = width
      canvas.height = height
      const context = canvas.getContext('2d')

      let bgImg = new Image()
      let qrImg = new Image()
      bgImg.setAttribute('crossOrigin', 'Anonymous')
      bgImg.src = item.img
      bgImg.onload = function () {
        context.drawImage(bgImg, 0, 0, width, height)
        qrImg.src = _this.qrCodeImg

        if (_this.posterType === 'default') {
          context.font = '38px bold 宋体'
          context.fillStyle = '#fff'
          context.fillText(_this.userName, 180, 70)
          context.textAlign = 'right'
          context.fillText(_this.agentConfig.bwcName, 950, 70)
        }
      }
      qrImg.onload = function () {
        context.drawImage(qrImg, item.code_X, item.code_Y, item.codeWidth, item.codeHeight)
        let index = _this.posterList.findIndex((v) => v.id === item.id)
        let base64 = canvas.toDataURL('image/png')
        _this.$set(_this.posterList[index], 'base64', base64)
      }
    },

    // app兼容-保存图片到本地
    saveImgApp(e) {
      const base64 = this.posterList[this.key].base64
      uni.postMessage({
        data: {
          type: 'saveImgFile',
          base64
        }
      })
    },

    // app兼容-微信分享
    saveWechat(val) {
      let shareUrl = ''
      if (this.custMsg.custId) {
        shareUrl = `${this.agentConfig.SELF_DOMAIN_USER}/?agentId=${localStorage.getItem('agent')}&parentId=${this.custMsg.custId}`
      } else {
        shareUrl = `${this.agentConfig.SELF_DOMAIN_USER}/?agentId=${localStorage.getItem('agent')}`
      }
      let imgUrl =
        this.agentConfig.WECHAT_SHARE_IMG || 'https://xf-photo.oss-cn-guangzhou.aliyuncs.com/xifan-oss/day180/202207051656248axhN8Rj.jpg'
      let title = this.agentConfig.WECHAT_SHARE_TITLE || '请你免费吃外卖霸王餐'
      let desc = this.agentConfig.WECHAT_SHARE_DESC || '每天限量1000份'
      uni.postMessage({
        data: {
          type: val,
          option: {
            href: shareUrl,
            title: title,
            summary: desc,
            imageUrl: imgUrl
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.img-box {
  text-align: center;
  img {
    border-radius: 3vw;
    width: 340px;
  }
}
.main-poster {
  margin-top: 12vw;
  min-height: 90vw;
}
.swiper-button {
  margin-top: 10vw;
  text-align: center;
  font-size: 4vw;
}
.poster-text {
  margin: 6vw 4vw;
  font-size: 3.6vw;
  color: #666;
}
.poster-rule {
  margin-top: 2vw;
}
.share-box {
  display: flex;
  margin: 6vw 4vw;

  .item {
    color: #666;
    text-align: center;
    margin-right: 12px;
    .image img {
      width: 8vw;
    }
  }
}
</style>
